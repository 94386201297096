.nbo{


    &__services-wrapper{
        width: calc(100% - 30px);
        margin: 15px;
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__services-title{
        // margin-right: 80px;
        width: 225px;
    }

    &__services-list-wrapper{
        flex-grow: 1;
    }

    &__service-wrapper{
        display: flex;
        flex-grow: 1;
    }

    &__service-index{
        font-weight: bold;
        margin-right: 40px;
    }

    &__service-name{
        width: 225px;
        font-weight: bold;
        cursor: pointer;
    }

    &__service-description{
        text-wrap: nowrap;
        width: 0;
        transition: width 0.4s ease-in-out;
        overflow: hidden;
        &.active{
            width: 70%;
        }
    }
}

@include mobile{

    .nbo{


        &__services-wrapper{
            width: calc(100% - 30px);
            margin: 50px 15px;
            display: block;
            position: relative;
        }

        &__services-title{
            width: 100%;
            margin-bottom: 8px;
        }

        &__service-wrapper{
            display: block;
            width: 100%;
            margin-bottom: 8px;
        }

        &__service-name{
            width: 100%;
            margin-bottom: 2px;
        }

        &__service-description{
            text-wrap: wrap;
            width: calc(100% - 45px);
            margin-left: 46px;
        }

    }
}