@font-face {
    font-family: 'HNL';
    src: url('../assets/fonts/HNL.eot');
    src: url('../assets/fonts/HNL.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HNL.woff2') format('woff2'),
        url('../assets/fonts/HNL.woff') format('woff'),
        url('../assets/fonts/HNL.ttf') format('truetype'),
        url('../assets/fonts/HNL.svg#HNL') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


html,
body{
    font-size: 10px;
    line-height: 12px;
    color: #FFF;
    font-family: 'HNL', sans-serif;
    text-transform: uppercase;
}

a{
    text-decoration: underline;
    color: inherit;
}

strong{
    font-weight: bold;
}

em{
    font-style: italic;
}