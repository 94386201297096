*::selection {
    background: #000;
    color: #FFF;
  }

html,
body{
    background-color: #000;
}

.nbo{

    &__content-wrapper{
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }

    &__carousel-wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
    }

    &__info-wrapper{
        position: fixed;
        top: 0;
        left: 0;
        margin: 15px;
        width: 30%;
        max-width: 660px;
        max-height: calc(var(--app-height) - 200px);
        z-index: 2;
        p{
            margin-bottom: 8px;
        }

        // padding: 15px;
        // background-color: rgba(#DDDDDD, 0.3);
        // backdrop-filter: blur(15px);
        // border-radius: 5px;
    }

    &__logo{
        position: absolute;
        top: calc(100% + 50px);
        width: 100vw;
        left: -15px;
        z-index: 2;
    }

    &__time-wrapper{
        display: block;
        margin-bottom: 8px;
    }
    &__founders-wrapper{
        position: fixed;
        top: 0;
        right: 0;
        margin: 15px;
        max-height: calc(var(--app-height) - 200px);
        text-align: right;
        z-index: 2;
    }

    &__background-wrapper{
        position: fixed;
        width: 100%;
        height: var(--app-height);
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
    }
}

@include mobile{

    .nbo{


    
        &__info-wrapper{
            position: relative;
            width: calc(100% - 30px);
            // margin: 0;
            max-width:none;
            max-height:none;
            // padding: 15px;
        }

        &__clientele{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }

        &__clientele-half{
            width: calc(50% - 10px);
            margin-right: 10px;
        }
    
        &__logo{
            position: relative;
            top: auto;
            width: 100%;
            left: 0;
            margin-top: 30px;
        }
    
        &__founders-wrapper{
            position: relative;
            margin: 30px 15px;
            max-height: none;
            text-align: left;
        }
    
    }
}