.intro{

    &__wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        transition: opacity 0.4s ease-in-out;
        pointer-events: none;
        z-index: 2;
        &.disabled{
            opacity: 0;
        }
    }

    &__logo{
        display: block;
        position: absolute;
        height: 100%;
        width: auto;
        top: 0;
        left: 100%;
        // left: 0;
        transition: left 1.5s ease-in-out;
    }
}